import { configureStore } from '@reduxjs/toolkit'
import ticketFormReducer from './features/ticketFormSlice'
import ticketConfirmReducer from './features/ticketConfirmSlice'
import userAdminReducer from './features/userAdminSlice'

export default configureStore({
  reducer: {
    ticketForm: ticketFormReducer,
    ticketConfirm: ticketConfirmReducer,
    userAdmin: userAdminReducer,
  },
})
