import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setTech } from 'src/store/features/ticketFormSlice'
import postTicket from 'src/store/api/postTicket'
import getMyLogin from 'src/store/api/getMyLogin'
import { techList } from 'src/data/techList'
import { Autocomplete, IconButton, Stack, TextField, Tooltip } from '@mui/material'
import TouchAppIcon from '@mui/icons-material/TouchApp'

export default function AssignedTech() {
  const dispatch = useDispatch()
  const CCs = useSelector((state) => state.ticketForm.CCs)
  const client = useSelector((state) => state.ticketForm.client)
  const contact = useSelector((state) => state.ticketForm.contact)
  const details = useSelector((state) => state.ticketForm.details)
  const dueDate = useSelector((state) => state.ticketForm.dueDate)
  const issueType = useSelector((state) => state.ticketForm.issueType)
  const openDate = useSelector((state) => state.ticketForm.openDate)
  const priority = useSelector((state) => state.ticketForm.priority)
  const tech = useSelector((state) => state.ticketForm.tech)
  const ticketId = useSelector((state) => state.ticketForm.ticketId)
  const title = useSelector((state) => state.ticketForm.title)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  // detect your login and fill Assigned Tech
  useEffect(() => {
    // skip if ticket is loaded from TicketList
    if (ticketId) return

    const fetchMyLogin = async () => {
      const getMyLoginResult = await getMyLogin()
      dispatch(setTech(getMyLoginResult))
    }
    fetchMyLogin()
  }, [ticketId, dispatch])

  // enable take button when ticket is selected
  useEffect(() => {
    ticketId ? setButtonDisabled(false) : setButtonDisabled(true)
  }, [ticketId])

  const handleTech = async (event, input) => {
    console.log('assignedTech:', input)
    dispatch(setTech(input))
  }

  const handleTake = async () => {
    const myLoginResult = await getMyLogin()
    dispatch(setTech(myLoginResult))

    const ticketJSON = JSON.stringify({
      accountId: client.id,
      assigneeId: tech.id,
      contactId: contact.id,
      ccs: CCs,
      details: details,
      dueDate: dueDate,
      issueTypeID: issueType.id,
      locationId: client.locationId,
      openDate: openDate,
      priorityId: priority,
      sourceId: 4, //Email
      statusId: 36709,
      title: title,
      typeId: 28, //Service Request
      queueID: 27976,
    })
    console.log('ticketJSON:', ticketJSON)
    const postTicketResult = await postTicket(ticketJSON, ticketId)
    console.log('postTicketResult:', postTicketResult)
  }

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Autocomplete
          sx={{ width: '95%' }}
          autoHighlight
          value={tech}
          options={techList}
          onChange={handleTech}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Assigned Tech" variant="outlined" size="small" />
          )}
        />
        <Tooltip title="Take ticket">
          <IconButton
            sx={{ width: '5%' }}
            color="primary"
            disabled={buttonDisabled}
            onClick={handleTake}
          >
            <TouchAppIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </>
  )
}
