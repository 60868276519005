import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActivities } from 'src/store/features/ticketFormSlice'
import { Box, Stack, Typography } from '@mui/material'
import getTicketActivities from 'src/store/api/getTicketActivities'
import Details from 'src/components/activities/Details'

export default function Activities() {
  const dispatch = useDispatch()
  const ticketId = useSelector((state) => state.ticketForm.ticketId)
  const activities = useSelector((state) => state.ticketForm.activities)
  useEffect(() => {
    const fetchTicketActivities = async () => {
      const ticketActivitiesResult = await getTicketActivities(ticketId)
      console.log('ticketActivities:', ticketActivitiesResult)
      dispatch(setActivities(ticketActivitiesResult))
    }
    fetchTicketActivities()
  }, [ticketId, dispatch])

  return (
    <div>
      <br />
      {activities.map((activity, index) => {
        return (
          <div key={index}>
            <br />
            {
              <div>
                {activity.activityType === 'Status Changed' && (
                  <div>
                    {activity.notes} → {activity.internalNotes}
                  </div>
                )}
                {activity.activityType === 'Email Received' && (
                  <Stack direction="row">
                    <Box bgcolor="#D8D8E2" sx={{ mr: 5, p: 2, borderRadius: '10px' }}>
                      <Typography color="#000000">
                        <div>
                          <b style={{ paddingRight: '6px' }}>{activity.createdByName}</b>
                          <span>
                            {new Date(activity.createdOn + 'z').toLocaleString('en-US', {
                              timeZone: 'America/Los_Angeles',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: '2-digit',
                            })}
                          </span>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: activity.notes }} />
                      </Typography>
                    </Box>
                  </Stack>
                )}
                {activity.activityType !== 'Status Changed' &&
                  activity.activityType !== 'Email Received' && (
                    <Stack direction="row">
                      <Box bgcolor="#1982FC" sx={{ ml: 7, p: 2, borderRadius: '10px' }}>
                        <Typography color="#FFFFFF">
                          <div>
                            <b style={{ paddingRight: '6px' }}>{activity.createdByName}</b>
                            <span>
                              {new Date(activity.createdOn + 'z').toLocaleString('en-US', {
                                timeZone: 'America/Los_Angeles',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: '2-digit',
                              })}
                            </span>
                          </div>
                          <div dangerouslySetInnerHTML={{ __html: activity.notes }} />
                        </Typography>
                      </Box>
                    </Stack>
                  )}
              </div>
            }
          </div>
        )
      })}
      <br />
      <Details />
    </div>
  )
}
