import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Stack, Typography } from '@mui/material'
import UserSelect from './UserSelect'
import Password from './Password'
import execPassReset from 'src/store/api/execPassReset'

export default function PassReset() {
  const client = useSelector((state) => state.ticketForm.client)
  const contact = useSelector((state) => state.ticketForm.contact)
  const user = useSelector((state) => state.userAdmin.user)
  const password = useSelector((state) => state.userAdmin.password)
  const [resultMsg, setResultMsg] = useState()

  const resetHandler = async () => {
    const shippedValues = {
      tenantID: client.tenantId,
      userID: user.id,
      password: password,
    }
    const passResetResult = await execPassReset(shippedValues)
    setResultMsg(passResetResult.Results)
  }

  return (
    <Stack spacing={2}>
      <UserSelect />
      <Password
        contactFirstname={contact?.name.split(' ')[0]}
        userFirstname={user?.name.split(' ')[0]}
        email={user?.emailAddress}
      />
      <Button onClick={resetHandler} color="warning" variant="contained">
        Reset Password
      </Button>
      <Typography color="#66bb6a">{resultMsg}</Typography>
    </Stack>
  )
}
