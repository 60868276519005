import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import postTicket from '../../store/api/postTicket'
import postTime from '../../store/api/postTime'
import { resetForm } from '../../store/features/ticketFormSlice'
import { setTicketConfirmId, setTimeEntryConfirmId } from '../../store/features/ticketConfirmSlice'

export default function Submit() {
  const dispatch = useDispatch()
  const CCs = useSelector((state) => state.ticketForm.CCs)
  const client = useSelector((state) => state.ticketForm.client)
  const contact = useSelector((state) => state.ticketForm.contact)
  const details = useSelector((state) => state.ticketForm.details)
  const dueDate = useSelector((state) => state.ticketForm.dueDate)
  const issueType = useSelector((state) => state.ticketForm.issueType)
  const notes = useSelector((state) => state.ticketForm.notes)
  const openDate = useSelector((state) => state.ticketForm.openDate)
  const priority = useSelector((state) => state.ticketForm.priority)
  const queue = useSelector((state) => state.ticketForm.queue)
  const statusId = useSelector((state) => state.ticketForm.statusId)
  const tech = useSelector((state) => state.ticketForm.tech)
  const ticketId = useSelector((state) => state.ticketForm.ticketId)
  const title = useSelector((state) => state.ticketForm.title)
  const timeEntry = useSelector((state) => state.ticketForm.timeEntry)

  const handleSubmit = async () => {
    const now = new Date()
    const nowISO = now.toISOString()
    const startTime = new Date(now.getTime() - timeEntry * 60000)
    const startTimeUTC = startTime.toLocaleTimeString('en-GB', { timeZone: 'UTC' })
    const endTimeUTC = now.toLocaleTimeString('en-GB', { timeZone: 'UTC' }) // format time in GMT for start/end time
    const ticketJSON = JSON.stringify({
      accountId: client.id,
      assigneeId: tech.id,
      contactId: contact.id,
      ccs: CCs,
      details: ticketId ? details : title, // existing ticket ? keep current value : new value
      dueDate: dueDate,
      issueTypeID: issueType.id,
      locationId: client.locationId,
      openDate: ticketId ? openDate : nowISO, // existing ticket ? keep current value : new value
      priorityId: priority,
      sourceId: 4, //Email
      statusId: statusId,
      title: title,
      typeId: 28, //Service Request
      queueID: queue,
    })
    console.log('ticketJSON:', ticketJSON)

    // ticketId is an optional parameter
    // IF ticketId exists THEN the ticket is updated
    // ELSE a new ticket is created
    const postTicketResult = await postTicket(ticketJSON, ticketId)
    console.log('ticketConfirmId:', postTicketResult)
    dispatch(setTicketConfirmId(postTicketResult))

    // Post time entry
    if (notes) {
      const timeHours = timeEntry / 60 // convert to hours in decimal
      const timeJSON = JSON.stringify({
        startDate: nowISO,
        startTime: startTimeUTC,
        endTime: endTimeUTC,
        timespent: timeHours,
        notes: notes,
        userId: tech.id,
        workTypeId: 19558,
        roleId: 15324,
      })
      const postTimeResult = await postTime(timeJSON, postTicketResult)
      console.log('timeEntryConfirmId:', postTimeResult)
      dispatch(setTimeEntryConfirmId(postTimeResult))
    }

    dispatch(resetForm())
  }

  return (
    <>
      {statusId && (
        <Button type="submit" onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      )}
    </>
  )
}
