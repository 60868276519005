import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setNotes } from '../../store/features/ticketFormSlice'
import { TextField } from '@mui/material'
import TimeEntry from './TimeEntry'

export default function TimeEntryNotes() {
  const dispatch = useDispatch()
  const notes = useSelector((state) => state.ticketForm.notes)

  const handleNotes = (event) => {
    dispatch(setNotes(event.target.value))
  }

  return (
    <>
      <TextField
        color="success"
        sx={{ pt: 2 }}
        label="Time Entry Notes"
        value={notes}
        onChange={handleNotes}
        multiline
        rows={6}
      />
      {notes && <TimeEntry />}
    </>
  )
}
