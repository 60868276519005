import axios from 'axios'

const getTicket = async (ticketID) => {
  const query = ticketID ? `?ticket_id=${ticketID}` : ''
  const axiosParam = {
    method: 'get',
    url: `/api/GetTicket${query}`,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const result = await axios(axiosParam)
  return result.data.result
}

export default getTicket
