import axios from 'axios'
import { techList } from 'src/data/techList'

const getMyLogin = async () => {
  const axiosParam = {
    method: 'get',
    url: '/.auth/me',
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const getMyLoginResult = await axios(axiosParam)

  if (!getMyLoginResult.data.clientPrincipal) {
    window.location.href = '/.auth/login/aad'
  }

  const myLogin = techList.find(
    (tech) => tech.userId === getMyLoginResult.data.clientPrincipal.userDetails,
  )
  return myLogin
}

export default getMyLogin
