import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import getTicketList from 'src/store/api/getTicketList'
import { Link, Typography } from '@mui/material'

export default function RelatedTickets() {
  const [ticketList, setTicketList] = useState()
  const client = useSelector((state) => state.ticketForm.client)
  const issueType = useSelector((state) => state.ticketForm.issueType)

  useEffect(() => {
    const fetchTicketList = async () => {
      const filter = {
        filter: {
          queueNames: 'Help Desk',
          excludeCompleted: 0,
          accountIds: client?.id,
          issueTypeNames: issueType?.label,
        },
        pageSize: 5,
      }
      const ticketListResult = await getTicketList(filter)
      console.log('Related Tickets:', ticketListResult)
      setTicketList(ticketListResult)
    }
    fetchTicketList()
  }, [client, issueType])

  return (
    <div>
      <br />
      <Typography variant="h5" color="#7b8488">
        Related Tickets
      </Typography>
      {ticketList?.map((e) => (
        <div key={e.id}>
          <span style={{ paddingRight: '4px', color: '#9c7aae' }}>
            {new Date(e.modifiedOn).toLocaleDateString('en-US', {
              day: '2-digit',
              month: '2-digit',
            })}
          </span>
          <span style={{ paddingRight: '4px' }}>{e.contactName}</span>
          <Link href={`https://bms.kaseya.com/react/servicedesk/tickets/${e.id}`} target="_blank">
            {e.title}
          </Link>
        </div>
      ))}
      <br />
    </div>
  )
}
