import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack, Typography } from '@mui/material'

export default function Details() {
  const details = useSelector((state) => state.ticketForm.details)
  const openDate = useSelector((state) => state.ticketForm.openDate)

  return (
    <>
      {details && (
        <Stack direction="row">
          <Box bgcolor="#D8D8E2" sx={{ mr: 10, p: 2, borderRadius: '10px' }}>
            <Typography color="#000000">
              <div>
                <b style={{ paddingRight: '6px' }}>Original Message</b>
                <span>
                  {new Date(openDate + 'z').toLocaleString('en-US', {
                    timeZone: 'America/Los_Angeles',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                  })}
                </span>
              </div>
              <div dangerouslySetInnerHTML={{ __html: details }} />
            </Typography>
          </Box>
        </Stack>
      )}
    </>
  )
}
