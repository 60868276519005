import axios from 'axios'

const postTime = async (timeJSON, ticketId) => {
  const axiosParam = {
    method: 'post',
    url: `/api/AddTime?ticket_id=${ticketId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: timeJSON,
  }

  const postTimeResult = await axios(axiosParam)
  return postTimeResult.data.result.id
}

export default postTime
