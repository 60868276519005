import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, TextField } from '@mui/material'
import { setUser } from 'src/store/features/userAdminSlice'

export default function User() {
  const dispatch = useDispatch()
  const contactList = useSelector((state) => state.ticketForm.contactList)
  const user = useSelector((state) => state.userAdmin.user)

  const handleUser = async (event, input) => {
    console.log('Selected user:', input)
    dispatch(setUser(input))
  }

  return (
    <Autocomplete
      autoHighlight
      autoSelect
      value={user}
      options={contactList}
      onChange={handleUser}
      getOptionLabel={(option) =>
        option.name ? option.name + '  <' + option.emailAddress + '>' : ''
      }
      renderInput={(params) => <TextField {...params} label="User" size="small" />}
    />
  )
}
