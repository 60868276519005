import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import postContact from '../../store/api/postContact'
import { setContact, setContactList } from '../../store/features/ticketFormSlice'
import getContactList from '../../store/api/getContactList'

export default function NewContact() {
  const dispatch = useDispatch()
  const client = useSelector((state) => state.ticketForm.client)
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()

  const handleNewContact = async () => {
    const contactJSON = {
      firstName: firstName,
      lastName: lastName,
      accountId: client.id,
      locationId: client.locationId,
      emails: [
        {
          emailAddress: email,
          emailTypeId: 1,
          isDefault: true,
        },
      ],
      phones: [
        {
          phoneNumber: '000-000-0000',
          phoneTypeId: 2,
          isDefault: true,
        },
      ],
    }

    // create new contact
    const postContactResult = await postContact(contactJSON)

    // fill contact field with newly created contact
    dispatch(setContact(postContactResult))

    // reload contact list
    const contactListResult = await getContactList(client.id)
    dispatch(setContactList(contactListResult))

    // reset form
    setFirstName('')
    setLastName('')
    setEmail('')
  }

  return (
    <>
      <Box
        sx={{
          p: 2,
          bgcolor: '#242c2c',
          border: '1px dashed grey',
          gap: 2,
        }}
      >
        <Stack spacing={2}>
          <TextField
            label="First Name"
            variant="standard"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            label="Last Name"
            variant="standard"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            label="Email"
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button variant="contained" onClick={handleNewContact}>
            Add
          </Button>
        </Stack>
      </Box>
    </>
  )
}
