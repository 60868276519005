import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { issueTypeList } from 'src/data/issueTypeList'
import { setIssueType } from 'src/store/features/ticketFormSlice'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import IssueTypeCount from 'src/components/ticketForm/IssueTypeCount'

export default function IssueType() {
  const dispatch = useDispatch()
  const issueType = useSelector((state) => state.ticketForm.issueType)

  const handleIssueType = (event, input) => {
    if (input === null) {
      dispatch(setIssueType({ label: '' }))
      return
    }
    dispatch(setIssueType(input))
  }

  return (
    <>
      <Autocomplete
        openOnFocus
        autoHighlight
        size="small"
        value={issueType}
        options={issueTypeList}
        onChange={handleIssueType}
        renderInput={(params) => <TextField {...params} label="Issue Type" variant="standard" />}
      />
      {!issueType.id && <IssueTypeCount />}
    </>
  )
}
