import React from 'react'
import { useSelector } from 'react-redux'
import { Link, Typography } from '@mui/material'
import ScreenConnect from 'src/images/ScreenConnect.png'
import ITGlue from 'src/images/ITGlue.png'
import Office365 from 'src/images/Office365.png'
import Azure from 'src/images/Azure.png'
import AzureAD from 'src/images/AzureAD.png'
import Intune from 'src/images/Intune.png'

export default function Speedloader() {
  const client = useSelector((state) => state.ticketForm.client)
  const contact = useSelector((state) => state.ticketForm.contact)
  return (
    <>
      <Typography variant="h5" color="#7b8488">
        Speedloader
      </Typography>
      {client?.name && (
        <div>
          <img src={ScreenConnect} alt="ScreenConnect" style={{ paddingRight: '5px' }} />
          <Link
            href={`https://relion.screenconnect.com/Host#Access/All%20Machines%1F${client.name}`}
            target="_blank"
          >
            ScreenConnect/{client.name}
          </Link>
        </div>
      )}
      {contact?.emailAddress && (
        <div>
          <img src={ScreenConnect} alt="ScreenConnect" style={{ paddingRight: '5px' }} />
          <Link
            href={`https://relion.screenconnect.com/Host#Access/All%20Machines%1F${
              client.name
            }/${contact.emailAddress.substring(0, contact.emailAddress.indexOf('@'))}`}
            target="_blank"
          >
            ScreenConnect/{client.name}/
            {contact.emailAddress.substring(0, contact.emailAddress.indexOf('@'))}
          </Link>
        </div>
      )}
      <div />
      {client?.itglue && (
        <div>
          <img src={ITGlue} alt="ITGlue" style={{ paddingRight: '5px' }} />
          <Link href={`https://relion.itglue.com/${client.itglue}`} target="_blank">
            ITGlue
          </Link>
        </div>
      )}
      {client?.itglue && (
        <div>
          <img src={ITGlue} alt="3CX" style={{ paddingRight: '5px' }} />
          <Link
            href={`https://relion.itglue.com/${client.itglue}/assets/113945-voice-pbx/records`}
            target="_blank"
          >
            3CX
          </Link>
        </div>
      )}
      {client?.tenantId && (
        <div>
          <img src={Office365} alt="Office365" style={{ paddingRight: '5px' }} />
          <Link
            href={`https://admin.microsoft.com/partner/beginclientsession.aspx?CTID=${client.tenantId}&CSDEST=o365admincenter`}
            target="_blank"
          >
            M365 Portal
          </Link>
        </div>
      )}
      {client?.tenantId && (
        <div>
          <img src={Intune} alt="Intune" style={{ paddingRight: '5px' }} />
          <Link href={`https://endpoint.microsoft.com/${client.defaultDomainName}`} target="_blank">
            Intune
          </Link>
        </div>
      )}
      {client?.tenantId && (
        <>
          <div>
            <img src={AzureAD} alt="AzureAD" style={{ paddingRight: '5px' }} />
            <Link href={`https://aad.portal.azure.com/${client.defaultDomainName}`} target="_blank">
              AAD Portal
            </Link>
          </div>
          <div>
            <img src={Azure} alt="Azure" style={{ paddingRight: '5px' }} />
            <Link href={`https://portal.azure.com/thinkrelion.com`} target="_blank">
              Azure Portal
            </Link>
          </div>
        </>
      )}
      <br />
    </>
  )
}
