import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  contact: { id: 0, displayName: '', userPrincipalName: '' },
  firstName: '',
  lastName: '',
  username: '',
  password: '',
}

export const userAdminSlice = createSlice({
  name: 'userAdmin',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setPassword: (state, action) => {
      state.password = action.payload
    },
  },
})

export const { setUser, setPassword } = userAdminSlice.actions

export default userAdminSlice.reducer
