import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { setContact, setContactList } from 'src/store/features/ticketFormSlice'
import NewContact from './NewContact'
import getContactList from 'src/store/api/getContactList'
import { Autocomplete, IconButton, Stack, TextField, Tooltip } from '@mui/material'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'

export default function Contact() {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const caller = searchParams.get('caller')
  const client = useSelector((state) => state.ticketForm.client)
  const contact = useSelector((state) => state.ticketForm.contact)
  const contactList = useSelector((state) => state.ticketForm.contactList)

  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [formVisible, setFormVisible] = useState(false)

  const handleContactChange = useCallback(
    (event, input) => {
      console.log('contact:', input)
      dispatch(setContact(input))
    },
    [dispatch],
  )

  // Fetch contactList
  useEffect(() => {
    const fetchContactList = async () => {
      const contactListResult = await getContactList(client?.id)
      console.log('contactList:', contactListResult)
      dispatch(setContactList(contactListResult))
    }
    fetchContactList()
  }, [client, dispatch])

  // Match contact with 3CX caller if supplied
  useEffect(() => {
    if (!caller?.includes(':')) return

    const lastName = caller.substring(caller.indexOf(':') + 2, caller.length)
    console.log('Caller lastName:', lastName)

    const match = contactList.filter((e) => e.name === lastName)
    console.log('Contact Match:', match[0])

    if (match[0]) {
      handleContactChange(null, match[0])
    }
  }, [caller, contactList, handleContactChange])

  // Enable new contact button when client is selected
  useEffect(() => {
    client?.id ? setButtonDisabled(false) : setButtonDisabled(true)
  }, [client])

  // Hide new contact form once contact is selected
  useEffect(() => {
    if (contact?.id) setFormVisible(false)
  }, [contact])

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Autocomplete
          sx={{ width: '95%' }}
          openOnFocus
          autoHighlight
          size="small"
          value={contact}
          getOptionLabel={(option) =>
            option.name ? option.name + '  <' + option.emailAddress + '>' : ''
          }
          options={contactList}
          onChange={handleContactChange}
          renderInput={(params) => <TextField {...params} label="Contact" variant="standard" />}
        />
        <Tooltip title="Create new contact">
          <IconButton
            sx={{ width: '5%' }}
            color="primary"
            disabled={buttonDisabled}
            onClick={() => {
              setFormVisible(!formVisible)
            }}
          >
            <PersonAddAlt1Icon />
          </IconButton>
        </Tooltip>
      </Stack>
      {formVisible && <NewContact />}
    </>
  )
}
