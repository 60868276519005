import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DataGrid } from '@mui/x-data-grid'
import { Accordion, AccordionDetails, Chip, CircularProgress, Link } from '@mui/material'
import { darken } from '@mui/material/styles'
import {
  setCCs,
  setCCList,
  setClient,
  setContact,
  setDetails,
  setIssueType,
  setOpenDate,
  setTech,
  setTitle,
  setTicketId,
} from 'src/store/features/ticketFormSlice'
import getTicketList from 'src/store/api/getTicketList'
import getTicket from 'src/store/api/getTicket'
import getMyLogin from 'src/store/api/getMyLogin'
import { clientList } from 'src/data/clientList'
import { techList } from 'src/data/techList'
import NinjaCat from 'src/images/NinjaCat.jpg'

export default function TicketList() {
  const dispatch = useDispatch()
  const [ticketList, setTicketList] = useState()

  // Fetch ticket list
  useEffect(() => {
    const fetchTicketList = async () => {
      const filter = {
        filter: {
          queueNames: 'Help Desk',
          excludeCompleted: 1,
        },
      }
      const ticketListResult = await getTicketList(filter)
      console.log('ticketList:', ticketListResult)
      setTicketList(ticketListResult)
    }

    // Initial load
    fetchTicketList()

    // Refresh every 6 sec
    const interval = setInterval(() => fetchTicketList(), 6000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleCellClick = async (param) => {
    // Exit function if BMS link was clicked
    if (param.field === 'id') return

    const row = param.row

    // Fetch client details
    const clientMatch = clientList.filter((e) => e.id === row.accountId)
    console.log('clientMatch:', clientMatch[0])

    // Fetch ticket details
    const ticketResult = await getTicket(row.id)
    console.log('ticketMatch', ticketResult)

    // Fetch tech details.  Assign self if blank.
    const tech = techList.find((e) => e.id === ticketResult.assigneeId)
    console.log('techMatch:', tech)
    if (tech) {
      dispatch(setTech(tech))
    } else {
      const myLoginResult = await getMyLogin()
      dispatch(setTech(myLoginResult))
    }

    // Populate remaining state
    dispatch(setClient(clientMatch[0]))
    dispatch(
      setContact({
        emailAddress: ticketResult.contactEmail,
        id: ticketResult.contactId,
        name: ticketResult.contactName,
      }),
    )
    dispatch(setCCs(ticketResult.cCs))
    dispatch(
      setIssueType({
        id: ticketResult.issueTypeId,
        label: ticketResult.issueTypeName,
      }),
    )
    dispatch(setDetails(ticketResult.details))
    dispatch(setOpenDate(ticketResult.openDate))
    dispatch(setTicketId(ticketResult.id))
    dispatch(setTitle(ticketResult.title))

    // Control CC field
    const CCList = ticketResult.cCs.map(({ emailAddress }) => emailAddress)
    dispatch(setCCList(CCList))
  }

  // Format status chip color
  function getChipProps(params) {
    switch (params.value) {
      case 'New':
        return {
          label: params.value,
          color: 'error',
        }
      case 'Client Responded':
        return {
          label: params.value,
          color: 'warning',
        }
      case 'Closed':
        return {
          label: params.value,
          sx: { color: '#678296' },
        }
      default:
        return {
          label: params.value,
          color: 'primary',
        }
    }
  }

  // Datagrid parameters
  const columns = [
    {
      field: 'id',
      headerName: 'Ticket',
      width: 50,
      renderCell: (cellValues) => {
        return (
          <Link
            href={`https://bms.kaseya.com/react/servicedesk/tickets/${cellValues.row.id}`}
            target="_blank"
          >
            BMS
          </Link>
        )
      },
    },
    {
      field: 'lastActivityUpdate',
      headerName: 'Modified',
      width: 120,
      valueFormatter: (params) => {
        const utc = new Date(params.value + 'z')
        return utc.toLocaleString([], {
          timeZone: 'America/Los_Angeles',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
        })
      },
    },
    { field: 'assigneeName', headerName: 'Tech', width: 110 },
    {
      field: 'statusName',
      headerName: 'Status',
      width: 120,
      renderCell: (cellValues) => {
        return <Chip variant="outlined" {...getChipProps(cellValues)} />
      },
    },
    { field: 'accountName', headerName: 'Client', width: 100 },
    { field: 'contactName', headerName: 'Contact', width: 120 },
    {
      field: 'issueTypeName',
      headerName: 'Issue Type',
      width: 125,
      renderCell: (cellValues) => {
        return <Chip variant="outlined" color="secondary" label={cellValues.value} />
      },
    },
    { field: 'title', headerName: 'Title', width: 500 },
  ]

  // Render JSX
  const showTicketList = () => {
    if (!ticketList) return <CircularProgress />
    if (ticketList.length > 0) {
      return (
        <DataGrid
          onCellClick={handleCellClick}
          rows={ticketList}
          columns={columns}
          rowHeight={38}
          pageSize={100}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'lastActivityUpdate',
                  sort: 'desc',
                },
              ],
            },
          }}
          getRowClassName={(params) => {
            const statusName = params.row.statusName.replace(' ', '-')
            return `rowstyle--${statusName}`
          }}
        />
      )
    } else {
      return <img src={NinjaCat} alt="Ninja Cat" />
    }
  }

  return (
    <Accordion
      defaultExpanded={true}
      sx={{
        backgroundColor: 'transparent',
      }}
    >
      <AccordionDetails
        sx={{
          height: 475,
          '& .rowstyle--New': {
            '&:hover': {
              bgcolor: '435252',
            },
          },
          '& .rowstyle--In-Progress': {
            '&:hover': {
              bgcolor: '435252',
            },
          },
          '& .rowstyle--Client-Responded': {
            '&:hover': {
              bgcolor: '435252',
            },
          },
          '& .rowstyle--Merged': {
            color: '#678296',
            bgcolor: '#242c2c',
            '&:hover': {
              bgcolor: darken('#242c2c', 0.6),
            },
          },
          '& .rowstyle--Closed': {
            color: '#678296',
            bgcolor: '#242c2c',
            '&:hover': {
              bgcolor: darken('#242c2c', 0.6),
            },
          },
        }}
      >
        {showTicketList()}
      </AccordionDetails>
    </Accordion>
  )
}
