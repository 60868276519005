import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setTitle } from '../../store/features/ticketFormSlice'
import TextField from '@mui/material/TextField'

export default function Title() {
  const dispatch = useDispatch()
  const title = useSelector((state) => state.ticketForm.title)

  const handleTitle = (event) => {
    dispatch(setTitle(event.target.value))
  }

  return (
    <TextField
      id="title"
      label="Title"
      size="small"
      value={title}
      variant="standard"
      onChange={handleTitle}
    />
  )
}
