export const clientList = [
  {
    id: 101238,
    name: 'ACW',
    defaultDomainName: 'acwla.org',
    domain: 'acwla.org',
    tenantId: '188495f2-336a-4cc3-868b-a4765f71185c',
    locationId: '215264',
    pax8: 'e4128a4c-e97d-47a1-89b5-7de75dba7b26',
    itglue: '3985386',
  },
  {
    id: 101239,
    name: 'AKP',
    defaultDomainName: 'akp-tech.com',
    domain: 'akp-tech.com',
    locationId: '215265',
    tenantId: '56d4ef54-2d64-4729-aca4-10a530f9e137',
    pax8: '058c1631-4a9e-4c89-bece-2b8bd4e2b649',
    itglue: '4973818',
  },
  {
    id: 101240,
    name: 'BASE',
    defaultDomainName: '',
    domain: 'basetrainingpt.com',
    tenantId: '',
    locationId: '171024',
    pax8: '',
    itglue: '3537152',
  },
  {
    id: 105196,
    name: 'BASE Sherman Oaks',
    locationId: '175812',
    defaultDomainName: '',
    domain: 'basetrainingpt.com',
    tenantId: '',
    pax8: '',
    itglue: '3537152',
  },
  {
    id: 101241,
    name: 'CI',
    defaultDomainName: '',
    domain: 'criticalinnovations.com',
    tenantId: '',
    locationId: '171025',
    pax8: '',
    itglue: '4211290',
  },
  {
    id: 101230,
    name: 'City of Rolling Hills Estates',
    defaultDomainName: 'ci.rolling-hills-estates.ca.us',
    domain: 'ci.rolling-hills-estates.ca.us',
    tenantId: '88879da4-54a3-4a21-aef5-c17e8980d0a1',
    locationId: '215260',
    pax8: 'f812b4fe-bb0b-41af-940c-5e03d60209d1',
    itglue: '3537164',
  },
  {
    id: 101242,
    name: 'DLITE',
    defaultDomainName: 'dekra-lite.com',
    domain: 'dekra-lite.com',
    tenantId: '6a8e5d53-3e2b-418a-9dd8-5ecc8337962a',
    locationId: '215266',
    pax8: 'fa641349-662f-48a3-91a5-e95537294c51',
    itglue: '3481800',
  },
  {
    id: 101243,
    name: 'Dolorosa',
    defaultDomainName: 'MaterDolorosa.org',
    domain: 'materdolorosa.org',
    tenantId: '4a74ab7f-6c1d-487e-bedb-f2244caf8adf',
    locationId: '215267',
    pax8: 'a06903bf-5274-4b3e-9ddb-d15646022553',
    itglue: '3537156',
  },
  {
    id: 101244,
    name: 'DWC',
    defaultDomainName: 'downtownwomenscenter.org',
    domain: 'downtownwomenscenter.org',
    tenantId: '92c60ce1-2f5d-44ed-9464-6422ebdff615',
    locationId: '215268',
    pax8: 'eb89ff07-c253-43d4-b4b7-baa2e4579142',
    itglue: '3200304',
  },
  {
    id: 101245,
    name: 'Extant',
    defaultDomainName: '',
    domain: 'extantlighting.com',
    tenantId: '',
    locationId: '171029',
    pax8: '',
    itglue: '3980390',
  },
  {
    id: 101247,
    name: 'F2',
    defaultDomainName: 'f2suspension.com',
    domain: 'f2suspension.com',
    tenantId: '9bca80f2-845d-4262-ba3a-66b2578c9098',
    locationId: '215269',
    pax8: '1669d165-6524-4e6c-bea4-c065ef050524',
    itglue: '4699634',
  },
  {
    id: 101248,
    name: 'FriendsLA',
    defaultDomainName: 'friendsla.org',
    domain: 'friendsla.org',
    tenantId: '2cc4db87-c199-41e0-9361-74b7d8bdf6b4',
    locationId: '215270',
    pax8: 'ba7a73f2-ead6-4510-96ec-83371adae75c',
    itglue: '4477817',
  },
  {
    id: 105939,
    name: 'Garfield',
    defaultDomainName: 'garfieldcoment.com',
    domain: 'garfieldcoment.com',
    tenantId: 'be17f196-dd65-4a88-ba34-96155e758c0e',
    locationId: '215271',
    pax8: '2b7e28a0-579d-4e37-92dc-7680ba4eb16f',
    itglue: '5944197',
  },
  {
    id: 101249,
    name: 'GCC',
    defaultDomainName: 'generalcarboncompany.com',
    domain: 'generalcarboncompany.com',
    tenantId: 'b77d26b2-b4b8-4527-b3f9-f2d97e3fb468',
    locationId: '215272',
    pax8: '55f7edb8-2822-4cf5-be5a-6205355767be',
    itglue: '3537155',
  },
  {
    id: 101250,
    name: 'Ho Rehab',
    defaultDomainName: 'horehab.com',
    domain: 'horehab.com',
    tenantId: '',
    locationId: '171034',
    pax8: '',
    itglue: '3537157',
  },
  {
    id: 101251,
    name: 'HOPT',
    defaultDomainName: 'hophysical.onmicrosoft.com',
    domain: 'hophysicaltherapy.com',
    tenantId: '3fffdedc-a531-4d72-be1b-894941bc9817',
    locationId: '215261',
    pax8: '96bf660d-590d-4208-9a6b-5989b4e28928',
    itglue: '3537158',
  },
  {
    id: 101252,
    name: 'ILA',
    defaultDomainName: 'imaginela.org',
    domain: 'imaginela.org',
    tenantId: '5256f2c6-e353-4350-a6af-df61ae110387',
    locationId: '215273',
    pax8: 'b9d1769e-ccc6-4065-8e40-c60e87bfab71',
    itglue: '5848054',
  },
  {
    id: 101253,
    name: 'KGC',
    defaultDomainName: 'kgcinc.com',
    domain: 'kgcinc.com',
    tenantId: 'be6fd3e8-8ffb-46e2-bf59-63fe1569d7a8',
    pax8: '43b0c83f-0615-4dba-9fdd-735aeef35350',
    locationId: '215274',
    itglue: '3523858',
  },
  {
    id: 101254,
    name: 'Kimble',
    defaultDomainName: 'NETORGFT932851.onmicrosoft.com',
    domain: 'kimblefire.com',
    tenantId: 'e71914b9-82ee-47a3-ba9d-8eac5151d7ab',
    locationId: '215276',
    pax8: '0aaffc3e-9335-4f01-8f29-0842cf8395cf',
    itglue: '4530408',
  },
  {
    id: 114089,
    name: 'LA Stagecall',
    defaultDomainName: 'lastagecall.onmicrosoft.com',
    domain: 'lastagecall.com',
    tenantId: 'c5e1f34a-934c-45fb-8a4f-2e88395c8a76',
    locationId: '190520',
    pax8: '6b18c599-ecbc-464d-91da-c4835a4eaad1',
    itglue: '6064482',
  },
  {
    id: 101255,
    name: 'LACS',
    defaultDomainName: 'losangeleschristianschool.org',
    domain: 'losangeleschristianschool.org',
    tenantId: '13f017e1-e5e0-49cb-b7fa-18233f5dfb12',
    locationId: '171039',
    pax8: '047af8d4-4ff4-41c6-9ad6-f5ce7be14571',
    itglue: '3537159',
  },
  {
    id: 101224,
    name: 'NZXT',
    defaultDomainName: '',
    domain: 'nzxt.com',
    tenantId: '',
    locationId: '171008',
    pax8: '',
    itglue: '3803897',
  },
  {
    id: 101225,
    name: 'PCSI',
    defaultDomainName: 'pyrocomm.com',
    domain: 'pyrocomm.com',
    tenantId: '98e954bc-6d5e-4cfc-bf1d-aca9d95d6917',
    locationId: '215277',
    pax8: '',
    itglue: '5672315',
  },
  {
    id: 101226,
    name: 'Phoenix CA',
    defaultDomainName: 'phoenixrope.net',
    domain: 'phoenixrope.net',
    tenantId: '4114297e-889f-4a48-afd7-e5c688f70639',
    locationId: '215279',
    pax8: 'ba8ea500-b4b5-4e4b-adad-e9f2550785dd',
    itglue: '5848055',
  },
  {
    id: 101227,
    name: 'Phoenix FL',
    defaultDomainName: 'phoenixrope.net',
    domain: 'phoenixrope.net',
    tenantId: '4114297e-889f-4a48-afd7-e5c688f70639',
    locationId: '215279',
    pax8: 'ba8ea500-b4b5-4e4b-adad-e9f2550785dd',
    itglue: '5848056',
  },
  {
    id: 101228,
    name: 'Powertec',
    defaultDomainName: 'powertecfitness.com',
    domaindomain: 'powertecfitness.com',
    tenantId: '04bcc83f-8f40-4656-a18b-4046d1fb30b3',
    locationId: '215280',
    pax8: '0cb0f357-84cd-4eb1-abd0-c348d735dba6',
    itglue: '3537161',
  },
  {
    id: 98695,
    name: 'Relion',
    defaultDomainName: '',
    domainName: 'teamrelion.com',
    tenantId: '3816fdaa-cba3-4869-ad2c-14d272154f76',
    locationId: '168102',
    pax8: '',
    itglue: '3177061',
  },
  {
    id: 101229,
    name: 'RFCU',
    defaultDomainName: 'ranchofcu.org',
    domain: 'ranchofcu.org',
    tenantId: '82f49824-c485-4c63-8c1c-c5b8ef09b526',
    locationId: '215262',
    pax8: '71e11b2b-eb95-4a18-b0b1-56c0daebfbec',
    itglue: '3803908',
  },
  {
    id: 101231,
    name: 'Rollouts',
    defaultDomainName: 'rolloutsquick.com',
    domain: 'rolloutsquick.com',
    tenantId: '0ca2d474-cf2a-4bdf-9b08-92b11a983e87',
    locationId: '215282',
    pax8: '692b0a21-fc17-4899-b533-5c5ed8404aa9',
    itglue: '5070061',
  },
  {
    id: 102538,
    name: 'Shoes4Grades',
    defaultDomainName: 'Shoes4grades.onmicrosoft.com',
    domain: 'shoes4grades.com',
    tenantId: '2a1f6a93-79a4-4a09-93d6-8bc88697986d',
    locationId: '172665',
    pax8: '7bf51687-eb40-49e9-8db6-00e2371a469b',
    itglue: '5840163',
  },
  {
    id: 113122,
    name: 'Shore Logistics',
    defaultDomainName: 'NETORGFT3354605.onmicrosoft.com',
    domain: 'shipwithshore.com',
    tenantId: '312e6833-d248-490a-acef-be497d7f1b11',
    locationId: '215283',
    pax8: '6bfea972-6367-4182-96d3-52b30ff1dfe0',
    itglue: '6037892',
  },
  {
    id: 112610,
    name: 'Superior Handforge',
    defaultDomainName: 'superiorforge.net',
    domain: 'superiorforge.net',
    tenantId: '77fa3d9b-21de-4a40-9bc5-74625f6c9382',
    locationId: '215284',
    pax8: '4cccaa8a-bdee-499f-9d58-2e387598f0a5',
    itglue: '6025725',
  },
  {
    id: 101232,
    name: 'Trademark',
    defaultDomainName: '',
    domain: 'trademark-hoist.com',
    tenantId: '',
    locationId: '171016',
    pax8: '',
    itglue: '3537166',
  },
  {
    id: 101233,
    name: 'TSE',
    defaultDomainName: '',
    domain: 'takshinghong.com',
    tenantId: '',
    locationId: '171017',
    pax8: '',
    itglue: '3978740',
  },
  {
    id: 101234,
    name: 'Walker',
    defaultDomainName: 'walkerbro.com',
    domain: 'walkerbro.com',
    tenantId: 'eab27e0a-90e2-414f-ad3a-ff0df0d2a2c9',
    locationId: '215285',
    pax8: 'deff0021-a4cb-44d0-8601-357a4d4080ca',
    itglue: '4149236',
  },
  {
    id: 101235,
    name: 'Warden',
    defaultDomainName: '',
    domain: 'warden.com',
    tenantId: '',
    locationId: '171019',
    pax8: '',
    itglue: '3537168',
  },
  {
    id: 101236,
    name: 'West Coast Trends',
    defaultDomainName: 'clubglove.com',
    domain: 'clubglove.com',
    tenantId: 'c531b5a1-496e-4b73-b205-e2b0cae113b5',
    locationId: '215263',
    pax8: 'e5eade04-c477-47c4-a44e-e52f6df712f7',
    itglue: '5848058',
  },
  {
    id: 101237,
    name: 'WI',
    defaultDomainName: 'worldimpact.org',
    domain: 'worldimpact.org',
    tenantId: 'f56b4ab8-3ab4-4537-ac2d-e2f1e3eabadc',
    locationId: '215286',
    pax8: '7944d7f3-7003-4b5f-abd5-9401bb48d680',
    itglue: '5848059',
  },
]
