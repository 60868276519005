import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPassword } from 'src/store/features/userAdminSlice'
import { Chip, Stack, Typography } from '@mui/material'
import { nouns } from 'src/data/nouns'
import { adjectives } from 'src/data/adjectives'
import { setNotes } from 'src/store/features/ticketFormSlice'

export default function Password(props) {
  const dispatch = useDispatch()
  const password = useSelector((state) => state.userAdmin.password)
  const [pass1, setPass1] = useState([])
  const [pass2, setPass2] = useState([])
  const [pass3, setPass3] = useState([])
  const [index1, setIndex1] = useState(0)
  const [index2, setIndex2] = useState(0)
  const [index3, setIndex3] = useState(0)

  // first part of the password is an adjective
  useEffect(() => {
    const randomAdj = adjectives[Math.floor(Math.random() * adjectives.length)]
    const randomAdjCapitalized = randomAdj.charAt(0).toUpperCase() + randomAdj.slice(1)
    setPass1(randomAdjCapitalized)
  }, [index1])

  // second part of the password is an noun
  // adjective + noun must be >= 10 and <= 14
  useEffect(() => {
    const lengthFilteredNouns = nouns.filter(
      (noun) => noun.length + pass1.length >= 10 && noun.length + pass1.length <= 14,
    )
    const randomNoun = lengthFilteredNouns[Math.floor(Math.random() * lengthFilteredNouns.length)]
    const randomNounCapitalized = randomNoun.charAt(0).toUpperCase() + randomNoun.slice(1)
    setPass2(randomNounCapitalized)
  }, [pass1, index2])

  // last part of the password is random digit and symbol
  useEffect(() => {
    const specialChar = ['!', '@', '#', '$', '%', '&', '*', '+', '-', '?', ':', '.']
    const randomNo = Math.floor(Math.random() * 9)
    const randomSpecialChar = specialChar[Math.floor(Math.random() * specialChar.length)]
    setPass3(randomSpecialChar.toString() + randomNo.toString())
  }, [index3])

  // combine all three password pieces together
  useEffect(() => {
    dispatch(setPassword(pass1 + pass2 + pass3))
  }, [pass1, pass2, pass3, dispatch])

  // Generate email message
  useEffect(() => {
    dispatch(
      setNotes(
        `Hi ${props.contactFirstname}\n\nHere is ${props.userFirstname}'s new login:\n\n     ${props.email} / ${password}`,
      ),
    )
  }, [props, password, dispatch])

  return (
    <Stack direction="row" spacing={0} sx={{ pt: 2, pb: 2 }}>
      <Typography>Generated Password =</Typography>&nbsp;
      <Chip
        label={pass1}
        variant="outlined"
        color="success"
        onClick={() => setIndex1(index1 + 1)}
      />
      <Chip
        label={pass2}
        variant="outlined"
        color="success"
        onClick={() => setIndex2(index2 + 1)}
      />
      <Chip
        label={pass3}
        variant="outlined"
        color="success"
        onClick={() => setIndex3(index3 + 1)}
      />
    </Stack>
  )
}
