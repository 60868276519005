import React, { useState } from 'react'
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import NewUser from './NewUser'
import PassReset from './PassReset'
//import Contact from './SelectedUser'
//import Offboard from './Offboard'
//import License from './License'

export default function UserAdmin() {
  const [tool, setTool] = useState('')

  const displayTool = () => {
    switch (tool) {
      case 'PassReset':
        return <PassReset />
      case 'NewUser':
        return <NewUser />
      default:
        return
    }
  }

  const handleButtonClick = (event, input) => {
    setTool(input)
  }

  return (
    <Stack spacing={1}>
      <Typography variant="h5" color="#7b8488">
        User Admin
      </Typography>
      <ToggleButtonGroup color="primary" value={tool} exclusive onChange={handleButtonClick}>
        <ToggleButton value="PassReset">Password Reset</ToggleButton>
        <ToggleButton value="NewUser">New User</ToggleButton>
      </ToggleButtonGroup>
      <br />
      {displayTool()}
    </Stack>
  )
}
