export const issueTypeList = [
  { id: 10490, label: 'Help Desk' },
  { id: 10487, label: 'User Admin' },
  { id: 10542, label: '3CX' },
  { id: 10480, label: 'Application' },
  { id: 12411, label: 'Data Recovery' },
  { id: 10486, label: 'Glitch' },
  { id: 12410, label: 'Gsuite' },
  { id: 10491, label: 'Infrastructure' },
  { id: 12307, label: 'M365' },
  { id: 10489, label: 'Maintenance' },
  { id: 10488, label: 'New Setup' },
  { id: 13100, label: 'Peripherals' },
  { id: 10481, label: 'Printing' },
  { id: 13147, label: 'Purchasing' },
  { id: 10484, label: 'Remote Access' },
  { id: 11401, label: 'Security' },
  { id: 12304, label: 'Smartphone' },
  { id: 10482, label: 'Workstation' },
]
