import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setCCs, setCCList } from 'src/store/features/ticketFormSlice'
import { TextField } from '@mui/material'

export default function CCs() {
  const dispatch = useDispatch()
  const CCList = useSelector((state) => state.ticketForm.CCList)

  const handleCCs = (event) => {
    // CCList controls the text field
    dispatch(setCCList(event.target.value))

    // Convert CCList to array of objects for final submission
    let ccArray = event.target.value.split(',')
    const CCs = ccArray.map((email) => ({
      emailAddress: email,
    }))
    console.log('CCs: ', CCs)
    dispatch(setCCs(CCs))
  }

  return (
    <TextField
      variant="standard"
      size="small"
      id="CCs"
      label="CCs"
      value={CCList}
      onChange={handleCCs}
    />
  )
}
