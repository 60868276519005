export const adjectives = [
  'big',
  'coy',
  'sassy',
  'small',
  'savage',
  'macho',
  'choosy',
  'manly',
  'curly',
  'snappy',
  'bumpin',
  'bumpy',
  'empty',
  'fancy',
  'fierce',
  'firm',
  'frail',
  'frumpy',
  'full',
  'glossy',
  'graceful',
  'grumpy',
  'hasty',
  'hollow',
  'hot',
  'icy',
  'jumpy',
  'lame',
  'lumpy',
  'mushy',
  'nervous',
  'commited',
  'scornful',
  'twerking',
  'lofty',
  'sublime',
  'exalted',
  'noble',
  'superior',
  'supreme',
  'prominent',
  'leading',
  'preeminent',
  'uncommon',
  'unusual',
  'unfamiliar',
  'scorching',
  'scalding',
  'loathsome',
  'sizzling',
  'sticky',
  'still',
  'slimy',
  'smart',
  'fabulous',
  'hefty',
  'slinky',
  'risky',
  'feverish',
  'amusing',
  'frisky',
  'friendly',
  'forlorn',
  'frightful',
  'flexible',
  'jealous',
  'snippy',
  'legit',
  'limber',
  'fantastic',
  'starry',
  'cordial',
  'corny',
  'witty',
  'hoarse',
  'coarse',
  'gangsta',
  'gangly',
  'worst',
  'thunderous',
  'rough',
  'wild',
  'worried',
  'sheepish',
  'bashful',
  'serene',
  'peaceful',
  'reliable',
  'steady',
  'stealthy',
  'steamy',
  'pirated',
  'happy',
  'elated',
  'elastic',
  'worthy',
  'boastful',
  'bouncy',
  'bony',
  'tasty',
  'nosy',
  'rosy',
  'loud',
  'humorous',
  'unruly',
  'seductive',
  'timid',
  'upset',
  'spiritual',
  'amused',
  'obscene',
  'willing',
  'wily',
  'slippery',
  'smelly',
  'messy',
  'bright',
  'colossal',
  'satisfied',
  'fishy',
  'fizzy',
  'fiery',
  'abstract',
  'active',
  'cold',
  'hot',
  'honest',
  'wary',
  'brilliant',
  'momentous',
  'toasted',
  'early',
  'late',
  'sleazy',
  'sly',
  'slick',
  'sliced',
  'sleepy',
  'lazy',
  'good',
  'dry',
  'fluffy',
  'flowery',
  'lucky',
  'potent',
  'regular',
  'gassy',
  'phony',
  'whacky',
  'harsh',
  'zippy',
  'frumpy',
  'open',
  'jolly',
  'adoring',
  'jovial',
  'vigorous',
  'joyful',
  'handsome',
  'soggy',
  'ancient',
  'historic',
  'comical',
  'filthy',
  'mighty',
  'important',
  'sick',
  'impossible',
  'complex',
  'expensive',
  'fat',
  'fallen',
  'faithful',
  'psychic',
  'feisty',
  'safe',
  'future',
  'nasty',
  'curvy',
  'puny',
  'obese',
  'bendy',
  'dynamic',
  'best',
  'windy',
  'intense',
  'winning',
  'nimble',
  'round',
  'emo',
  'deadly',
  'dangerous',
  'dashing',
  'delicious',
  'virtuous',
  'unlikely',
  'virile',
  'viral',
  'nice',
  'lovely',
  'magical',
  'elderly',
  'mad',
  'hearty',
  'earthy',
  'heroic',
  'country',
  'unique',
  'demonic',
  'monster',
  'robust',
  'unhealthy',
  'whole',
  'heavenly',
  'heavy',
  'silent',
  'stray',
  'silly',
  'thirsty',
  'absurd',
  'successful',
  'tiny',
  'dusty',
  'chemical',
  'secret',
  'playful',
  'placid',
  'juicy',
  'genius',
  'costly',
  'lumpy',
  'clumpy',
  'plump',
  'gentle',
  'lethal',
  'rare',
  'generous',
  'energetic',
  'electric',
  'careful',
  'spiky',
  'rich',
  'super',
  'common',
  'poor',
  'unbiased',
  'exotic',
  'hilarious',
  'large',
  'expensive',
  'snobby',
  'spanky',
  'spammy',
  'cheap',
  'fragile',
  'freaky',
  'free',
  'freezing',
  'frosty',
  'frozen',
  'bubbly',
  'blissful',
  'frothy',
  'muddy',
  'fun',
  'funny',
  'creative',
  'unnerving',
  'exciting',
  'excited',
  'flying',
  'deep',
  'helpful',
  'ghetto',
  'royal',
  'giant',
  'trippy',
  'calm',
  'almighty',
  'shy',
  'flashy',
  'flappy',
  'teeny',
  'fertile',
  'thankful',
  'fearless',
  'lonely',
  'adorable',
  'fervent',
  'wet',
  'wry',
  'crowned',
  'abused',
  'tasteful',
  'youthful',
  'beautiful',
  'fast',
  'quick',
  'quiet',
  'weak',
  'strong',
  'spry',
  'tangled',
  'glamorous',
  'glad',
  'gloomy',
  'loony',
  'enchanted',
  'gleeful',
  'inspired',
  'impatient',
  'patriot',
  'terrific',
  'superb',
  'upbeat',
  'super',
  'stunning',
  'simple',
  'cuddly',
  'quirky',
  'burly',
  'somber',
  'stoic',
  'serious',
  'sunny',
  'bossy',
  'agreeable',
  'alert',
  'lively',
  'amused',
  'angry',
  'yellow',
  'sore',
  'soft',
  'luscious',
  'screaming',
  'creamy',
  'laughing',
  'laborious',
  'boring',
  'stately',
  'dignified',
  'dim',
  'dimpled',
  'glowing',
  'imposing',
  'painful',
  'annoyed',
  'annoying',
  'anxious',
  'arrogant',
  'trusty',
  'true',
  'sultry',
  'ugly',
  'warm',
  'saucy',
  'hairy',
  'ashamed',
  'shady',
  'mean',
  'average',
  'awkward',
  'radical',
  'awful',
  'awesome',
  'soothing',
  'dope',
  'dopey',
  'inert',
  'bad',
  'bare',
  'kanye',
  'better',
  'blushing',
  'babyface',
  'diddy',
  'idle',
  'grody',
  'groovy',
  'glitzy',
  'glittery',
  'waxy',
  'wutang',
  'bored',
  'brainy',
  'bold',
  'brave',
  'brazen',
  'busy',
  'calm',
  'buggy',
  'careful',
  'cautious',
  'tipsy',
  'spongy',
  'plucky',
  'charming',
  'cheery',
  'cheeky',
  'cheerful',
  'clean',
  'organized',
  'classy',
  'clear',
  'clever',
  'cloudy',
  'clumsy',
  'colorful',
  'combative',
  'comfy',
  'concerned',
  'content',
  'confused',
  'cool',
  'polite',
  'itchy',
  'mortal',
  'chatty',
  'little',
  'soulful',
  'used',
  'loose',
  'crazy',
  'cranky',
  'pretty',
  'precious',
  'scanty',
  'smokin',
  'smooth',
  'creepy',
  'cruel',
  'crusty',
  'rusty',
  'yoked',
  'shredded',
  'modest',
  'curious',
  'stormy',
  'cute',
  'burnt',
  'burst',
  'nuclear',
  'dark',
  'darling',
  'sonic',
  'defeated',
  'defiant',
  'nutty',
  'sad',
  'different',
  'dirty',
  'difficult',
  'distinct',
  'disturbed',
  'sturdy',
  'dreamy',
  'dizzy',
  'doubtful',
  'drab',
  'drowsy',
  'droopy',
  'chummy',
  'chunky',
  'dull',
  'peppery',
  'peppy',
  'folksy',
  'orange',
  'green',
  'teal',
  'tiring',
  'pink',
  'picky',
  'weird',
  'purple',
  'pleasant',
  'easy',
  'brown',
  'merry',
  'tranquil',
  'quick',
  'light',
  'tall',
  'sour',
  'sweet',
  'rapid',
  'singing',
  'giddy',
  'righteous',
  'bitter',
  'skinny',
  'kingly',
  'breathy',
  'foxy',
  'foul',
  'hard',
  'holy',
  'snotty',
  'sneaky',
  'neat',
  'hopeful',
  'sloppy',
  'new',
  'icy',
  'fly',
  'slow',
  'raw',
  'edgy',
  'stinky',
  'spazzy',
  'spooky',
  'scary',
  'scared',
  'trembling',
  'rattled',
  'phat',
  'spoiled',
  'broken',
  'political',
  'kind',
  'kindly',
  'nifty',
  'guilty',
  'gummy',
  'husky',
  'peachy',
  'chubby',
  'woke',
  'dank',
  'janky',
  'jazzy',
  'salty',
  'yummy',
  'tight',
  'crass',
  'crabby',
  'hungry',
  'hangry',
  'smug',
  'gooey',
  'goofy',
  'smashing',
  'majestic',
  'mammoth',
  'mellow',
  'melted',
  'miserable',
  'moldy',
  'mushy',
  'mysterious',
  'nasty',
  'naughty',
  'nervous',
  'noisy',
  'nutty',
  'obnoxious',
  'odd',
  'old',
  'outrageous',
  'panicky',
  'animated',
  'perfect',
  'plain',
  'alert',
  'pleasant',
  'poised',
  'poor',
  'powerful',
  'gorgeous',
  'extra',
  'grim',
  'tragic',
  'trendy',
  'moody',
  'odd',
  'mangy',
  'chill',
  'chipper',
  'shallow',
  'chrome',
  'rockin',
  'chronic',
  'great',
  'greasy',
  'cinematic',
  'swift',
  'wimpy',
  'wiggly',
  'wise',
  'seedy',
  'stable',
  'rebel',
  'imperial',
  'perky',
  'zany',
  'popular',
  'poetic',
  'max',
  'greatest',
  'strange',
  'roasted',
  'gigantic',
  'gallant',
  'enormous',
  'stale',
  'talented',
  'short',
  'shocked',
  'sweaty',
  'jiggly',
  'slim',
  'jibilant',
  'uprooted',
  'proud',
  'baked',
  'blasted',
  'blazing',
  'yeet',
  'charred',
  'charmed',
  'cratered',
  'squashed',
  'squicky',
  'canned',
  'ripped',
  'flipped',
  'sparkly',
  'sparkling',
  'speedy',
  'chaste',
  'stanky',
  'studly',
  'hunky',
  'spendid',
]
