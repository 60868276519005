import axios from 'axios'

const execPassReset = async (shippedValues) => {
  const axiosParam = {
    method: 'post',
    url: `/api/NewPassphrase`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: shippedValues,
  }
  const result = await axios(axiosParam)
  return result.data
}

export default execPassReset
