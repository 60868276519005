import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Box,
  FormControlLabel,
  Button,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import Password from './Password'
import getUsers from 'src/store/api/getUsers'

export default function NewUser() {
  const dispatch = useDispatch()
  const client = useSelector((state) => state.ticketForm.client)
  const contact = useSelector((state) => state.ticketForm.contact)
  const password = useSelector((state) => state.userAdmin.password)

  // Form controllers
  const [firstname, setFirstname] = useState()
  const [lastname, setLastname] = useState()
  const [username, setUsername] = useState()

  // Username variations
  const [firstnameOnly, setFirstnameOnly] = useState()
  const [firstnameLastinit, setFirstnameLastinit] = useState()
  const [firstinitLastname, setFirstinitLastname] = useState()

  // Final output
  const [email, setEmail] = useState()
  const [cmd, setCmd] = useState()

  // Control snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [msg, setMsg] = useState(false)

  useEffect(() => {
    // Generate username variations
    setFirstnameOnly(firstname)
    setFirstnameLastinit(firstname + lastname?.charAt(0))
    setFirstinitLastname(firstname?.charAt(0) + lastname)

    // Generate Powershell command
    const fullname = firstname + ' ' + lastname
    setCmd(
      `$secureStringPwd = "${password}" | ConvertTo-SecureString -AsPlainText -Force; New-ADUser -Name "${fullname}" -DisplayName "${fullname}" -GivenName "${firstname}" -Surname "${lastname}" -SamAccountName "${username}" -UserPrincipalName "${email}" -Accountpassword $secureStringPwd -Enabled $true; Start-ADSyncSyncCycle -PolicyType delta`,
    )
  }, [email, firstname, lastname, password, username, dispatch])

  // Handlers
  const handleFirstnameChange = (event) => {
    setFirstname(event.target.value)
  }

  const handleLastnameChange = (event) => {
    setLastname(event.target.value)
  }

  const handleRadioChange = (event) => {
    setUsername(event.target.value)
    setEmail(event.target.value + '@' + client.domain)
  }

  const handleCopyClick = async () => {
    if (client.tenantId) {
      const usersResult = await getUsers(client.tenantId)
      console.log('usersResult:', usersResult)
      // const duplicate = usersResult?.filter((item) => item.userPrincipalName === email)
      // if (duplicate) {
      //   setMsg('Dupicate email found!')
      //   setSnackbarOpen(true)
      //   return
      // }
    }
    navigator.clipboard.writeText(cmd).then(
      function () {
        setMsg('Copied to clipboard')
      },
      function (err) {
        setMsg('Copy failed: ', err)
      },
    )
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  return (
    <>
      <Stack spacing={2} direction="row">
        <TextField
          value={firstname}
          onChange={handleFirstnameChange}
          label="First Name"
          size="small"
        />
        <TextField
          value={lastname}
          onChange={handleLastnameChange}
          label="Last Name"
          size="small"
        />
      </Stack>
      <RadioGroup value={username} onChange={handleRadioChange}>
        {firstname && (
          <FormControlLabel
            value={firstnameOnly}
            control={<Radio />}
            label={firstnameOnly + '@' + client.domain}
          />
        )}
        {firstname && lastname && (
          <>
            <FormControlLabel
              value={firstnameLastinit}
              control={<Radio />}
              label={firstnameLastinit + '@' + client.domain}
            />
            <FormControlLabel
              value={firstinitLastname}
              control={<Radio />}
              label={firstinitLastname + '@' + client.domain}
            />
          </>
        )}
      </RadioGroup>
      <Password
        contactFirstname={contact.name.split(' ')[0]}
        userFirstname={firstname}
        email={email}
      />
      {username && (
        <>
          <Button onClick={handleCopyClick}>
            <Typography variant="overline">Copy to Clipboard</Typography>
            <ContentCopyOutlinedIcon sx={{ ml: 1 }} />
          </Button>
          <Box
            sx={{
              p: 2,
              backgroundColor: '#013686',
            }}
          >
            <Typography>{cmd}</Typography>
          </Box>
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        message={msg}
      />
    </>
  )
}
