import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BarChart, Bar, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { setIssueType } from 'src/store/features/ticketFormSlice'
import { issueTypeList } from 'src/data/issueTypeList'
import getTicketList from 'src/store/api/getTicketList'

export default function IssueTypeCount() {
  const dispatch = useDispatch()
  const client = useSelector((state) => state.ticketForm.client)
  const contact = useSelector((state) => state.ticketForm.contact)
  const [issueTypeCount, setIssueTypeCount] = useState([])
  const [ticketHistory, setTicketHistory] = useState([])

  useEffect(() => {
    const fetchTicketHistory = async () => {
      const filter = {
        filter: {
          queueNames: 'Help Desk',
          excludeCompleted: 0,
          account: client?.name,
          contactName: contact?.name,
        },
      }
      const getTicketListResult = await getTicketList(filter)
      console.log('ticketHistory:', getTicketListResult)
      setTicketHistory(getTicketListResult)
    }
    fetchTicketHistory()
  }, [client, contact, dispatch])

  useEffect(() => {
    if (!ticketHistory) return
    // generate issueTypes count
    // https://stackoverflow.com/questions/44387647/group-and-count-values-in-an-array
    let counts = ticketHistory.reduce((p, c) => {
      let name = c.issueTypeName
      if (!p.hasOwnProperty(name)) {
        p[name] = 0
      }
      p[name]++
      return p
    }, {})

    // sort issueTypes by descending count
    // https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
    let countsExtended = Object.keys(counts).map((k) => {
      return { name: k, count: counts[k] }
    })
    countsExtended.sort((a, b) => (a.count < b.count ? 1 : b.count < a.count ? -1 : 0))
    console.log('issueTypeCount:', countsExtended)
    setIssueTypeCount(countsExtended)
  }, [ticketHistory])

  const handleClick = (issueTypeName) => {
    const issueTypeMatch = issueTypeList.filter((e) => e.label === issueTypeName)
    console.log('issueTypeMatch', issueTypeMatch[0])
    if (issueTypeMatch[0]) dispatch(setIssueType(issueTypeMatch[0]))
  }

  // calculate barchart height based on number of issue types
  // set min height to 110
  const height = issueTypeCount.length * 40 > 110 ? issueTypeCount.length * 40 : 110

  // state for color change on hover
  const [focusBar, setFocusBar] = useState()

  return (
    <>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          layout="vertical"
          data={issueTypeCount}
          margin={{
            top: 5,
            right: 30,
            left: 55,
            bottom: 5,
          }}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setFocusBar(state.activeTooltipIndex)
            } else {
              setFocusBar(null)
            }
          }}
        >
          <XAxis type="number" allowDecimals={false} />
          <YAxis type="category" dataKey="name" interval={0} tick={{ width: 150 }} />
          <Tooltip
            cursor={false}
            contentStyle={{ color: '#000000' }}
            allowEscapeViewBox={{ x: true, y: true }}
          />
          <Bar dataKey="count" barSize={20} label={{ position: 'right' }}>
            {issueTypeCount.map((issueType, index) => {
              switch (issueType.name) {
                case 'Help Desk':
                case 'User Admin':
                  return (
                    <Cell
                      key={index}
                      onClick={() => handleClick(issueType.name)}
                      fill={focusBar === index ? '#bbbbbb' : '#666666'}
                    />
                  )
                default:
                  return (
                    <Cell
                      key={index}
                      onClick={() => handleClick(issueType.name)}
                      fill={focusBar === index ? '#efb5ff' : '#a77fb1'}
                    />
                  )
              }
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}
