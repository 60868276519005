import axios from 'axios'

const getUsers = async (tenantId) => {
  const axiosParam = {
    method: 'get',
    url: `/api/ListUsers?TenantFilter=${tenantId}`,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const response = await axios(axiosParam)
  return response
}

export default getUsers
