import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Chip } from '@mui/material'
import { resetForm, setTech } from '../../store/features/ticketFormSlice'
import getMyLogin from 'src/store/api/getMyLogin'

export default function SelectedTicket() {
  const dispatch = useDispatch()
  const ticketId = useSelector((state) => state.ticketForm.ticketId)
  const label = 'Ticket # ' + ticketId

  const deleteHandler = async () => {
    dispatch(resetForm())
    const getMyLoginResult = await getMyLogin()
    dispatch(setTech(getMyLoginResult))
  }

  // Ticket chip appears when you select a row from the ticket list
  // The chip also allows you to exit edit mode
  return (
    <>
      {ticketId && <Chip label={label} onDelete={deleteHandler} color="primary" />}
      {!ticketId && (
        <Chip label="New Ticket" onDelete={deleteHandler} color="primary" variant="outlined" />
      )}
    </>
  )
}
