import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setStatusId,
  setDueDate,
  setQueue,
  setPriority,
} from '../../store/features/ticketFormSlice'
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'

export default function Status() {
  const dispatch = useDispatch()
  const title = useSelector((state) => state.ticketForm.title)
  const statusId = useSelector((state) => state.ticketForm.statusId)

  const handleStatus = (event) => {
    dispatch(setStatusId(event.target.value))
  }

  const clearDueDate = () => {
    dispatch(setDueDate(''))
    dispatch(setQueue('27976'))
    dispatch(setPriority('28791'))
  }

  const handleDueDate = (event) => {
    dispatch(setDueDate(new Date(event.target.value)))
    dispatch(setQueue('28295')) //change queue to Back Buner
    dispatch(setPriority('28789')) //change SLA to 2 days
  }

  return (
    <>
      {title && (
        <RadioGroup value={statusId} name="status-buttons-group" onChange={handleStatus}>
          <FormControlLabel
            value="36708"
            control={<Radio color="success" onChange={clearDueDate} />} //reset DueDate
            label="Closed"
          />
          <FormControlLabel
            value="36709"
            control={<Radio onChange={clearDueDate} />} //reset DueDate
            label="In Progress"
          />
          <FormControlLabel
            value="36707"
            control={<Radio color="warning" onChange={clearDueDate} />} //reset DueDate
            label="Waiting For Customer"
          />
          <FormControlLabel
            value="37182"
            control={<Radio color="warning" />}
            label="Follow-up Scheduled"
          />
          {['37182'].includes(statusId) && (
            <TextField type="datetime-local" onChange={handleDueDate} />
          )}
          <FormControlLabel
            value="37097"
            control={<Radio color="warning" />}
            label="Onsite Scheduled"
          />
          {['37097'].includes(statusId) && (
            <TextField type="datetime-local" onChange={handleDueDate} />
          )}
        </RadioGroup>
      )}
    </>
  )
}
