import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { setClient, setContact } from '../../store/features/ticketFormSlice'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { clientList } from '../../data/clientList'

export default function Client() {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const caller = searchParams.get('caller')
  const client = useSelector((state) => state.ticketForm.client)
  //const editMode = useSelector((state) => state.ticketForm.editMode)

  const handleClientChange = useCallback(
    (event, input) => {
      console.log('Selected Client:', input)
      dispatch(setClient(input))

      // Clear previously selected contact for another client
      dispatch(setContact({ name: '' }))
    },
    [dispatch],
  )

  // Match client with 3CX caller parameter
  useEffect(() => {
    if (!caller) return

    const firstName = caller.includes(':') ? caller.substring(0, caller.indexOf(':')) : caller
    console.log('Caller firstname:', firstName)

    const clientMatch = clientList.filter((client) => client.name === firstName)
    console.log('clientMatch:', console.log(clientMatch[0]))

    if (clientMatch[0]) {
      handleClientChange(null, clientMatch[0])
    }
  }, [caller, handleClientChange])

  return (
    <Autocomplete
      openOnFocus
      autoHighlight
      size="small"
      id="client"
      value={client}
      options={clientList}
      onChange={handleClientChange}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField {...params} label="Client" variant="standard" />}
    />
  )
}
