import React from 'react'
import { Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import AssignedTech from './AssignedTech'
import SelectedTicket from './SelectedTicket'
import Client from './Client'
import Contact from './Contact'
import CCs from './CCs'
import IssueType from './IssueType'
import Title from './Title'
import TimeEntryNotes from './TimeEntryNotes'
import Status from './Status'
import Submit from './Submit'
import SubmitConfirm from './SubmitConfirm'

export default function TicketForm() {
  const contact = useSelector((state) => state.ticketForm.contact)
  const issueType = useSelector((state) => state.ticketForm.issueType)

  return (
    <Stack spacing={2}>
      <SelectedTicket />
      <AssignedTech />
      <Client />
      <Contact />
      <CCs />
      {contact?.id && <IssueType />}
      {issueType?.id && (
        <>
          <Title />
          <TimeEntryNotes />
          <br />
          <Status />
          <Submit />
        </>
      )}
      <SubmitConfirm />
    </Stack>
  )
}
