import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activities: [],
  CCs: [],
  CCList: [],
  client: {
    defaultDomainName: '',
    domain: '',
    id: '',
    itglue: '',
    locationId: '',
    name: '',
    pax8: '',
    tenantId: '',
  },
  contact: { emailAddress: '', id: '', name: '' },
  contactList: [],
  details: '',
  editMode: false,
  issueType: { label: '' },
  label: '',
  tech: { label: '' },
  title: '',
  notes: '',
  statusId: '',
  dueDate: '',
  queue: 27976,
  priority: 28791,
  timeEntry: 15,
  ticketId: '',
}

export const ticketFormSlice = createSlice({
  name: 'ticketForm',
  initialState: initialState,
  reducers: {
    resetForm: () => initialState,
    setActivities: (state, action) => {
      state.activities = action.payload
    },
    setCCs: (state, action) => {
      state.CCs = action.payload
    },
    setCCList: (state, action) => {
      state.CCList = action.payload
    },
    setClient: (state, action) => {
      state.client = action.payload
    },
    setContact: (state, action) => {
      state.contact = action.payload
    },
    setContactList: (state, action) => {
      state.contactList = action.payload
    },
    setDetails: (state, action) => {
      state.details = action.payload
    },
    setDueDate: (state, action) => {
      state.dueDate = action.payload
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload
    },
    setIssueType: (state, action) => {
      state.issueType = action.payload
    },
    setNotes: (state, action) => {
      state.notes = action.payload
    },
    setOpenDate: (state, action) => {
      state.openDate = action.payload
    },
    setPriority: (state, action) => {
      state.priority = action.payload
    },
    setQueue: (state, action) => {
      state.queue = action.payload
    },
    setStatusId: (state, action) => {
      state.statusId = action.payload
    },
    setTech: (state, action) => {
      state.tech = action.payload
    },
    setTimeEntry: (state, action) => {
      state.timeEntry = action.payload
    },
    setTicketId: (state, action) => {
      state.ticketId = action.payload
    },
    setTitle: (state, action) => {
      state.title = action.payload
    },
  },
})

export const {
  resetForm,
  setActivities,
  setCCs,
  setCCList,
  setClient,
  setConfirmedTicketId,
  setContact,
  setContactList,
  setDetails,
  setDueDate,
  setEditMode,
  setIssueType,
  setNotes,
  setOpenDate,
  setPriority,
  setQueue,
  setRowSelection,
  setStatusId,
  setTech,
  setTicketId,
  setTimeEntry,
  setTitle,
} = ticketFormSlice.actions

export default ticketFormSlice.reducer
