import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import './App.css'
import TicketList from './components/ticketList/TicketList'
import TicketForm from './components/ticketForm/TicketForm'
import Activities from './components/activities/Activities'
import Speedloader from './components/tools/speedloader/Speedloader'
import UserAdmin from './components/tools/userAdmin/UserAdmin'
import RelatedTickets from './components/tools/relatedTickets/RelatedTickets'

export default function App() {
  // MUI dark mode
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  })

  const ticketId = useSelector((state) => state.ticketForm.ticketId)
  const client = useSelector((state) => state.ticketForm.client)
  const issueType = useSelector((state) => state.ticketForm.issueType)

  return (
    <ThemeProvider theme={darkTheme}>
      <Grid container spacing={4} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <TicketList />
        </Grid>
        <Grid item xs={6}>
          <TicketForm />
          {ticketId && <Activities />}
        </Grid>
        <Grid item xs={6}>
          {client?.id && <Speedloader />}
          {issueType?.label && issueType?.label !== 'Help Desk' && <RelatedTickets />}
          {issueType?.label === 'User Admin' && (
            <>
              <UserAdmin />
            </>
          )}
        </Grid>
      </Grid>
      <br />
    </ThemeProvider>
  )
}
